/**
 * 縦書きのページで、部分的な横書きを実現する。
 *
 * **実装上の注意**
 * : vertical.css が優先されているときもうまく動くようにするため、詳細度を `:where` 等で下げてはならない。
 *
 * <!-- FIXME: 自動展開したい -->
*/

/* cf. ./horizontal.css */

@custom-selector :--blockquote blockquote, .blockquote-like;
@custom-selector :--image figure, img, picture, svg;

%horizontal {
  writing-mode: horizontal-tb;
}

.horizontal {
  @extend %horizontal
}

/* .horizontal を元に戾す */
%revert-class-horizontal {
  writing-mode: revert;
  writing-mode: vertical-rl; /* Safari (15.1, iOS 15.2) では継承するか直接指定するかで振る舞いが変わることがあるので、明示的に指定している。 TODO: Delete this line */
}

:is(p, :--blockquote).horizontal {
  /* NOTE: 27em > 100vw - 2em のときは 100vw - 2em になるべきなので、min-width は使えない。 */
  width: min(
    max(
      29em,

      /* 100vh (w/ scroll bar) / √2 - (.body margin-inline) */
      100vh / 1.4142135624 - 2 * clamp(
        1rem,
        100vmin * 2.618034 / (2 * 2.618034 + 29),
        2.618034rem
      )
    ),

    /* 100vw - (margin-left-right itself) */
    100vw - 2em
  );

  max-width: fit-content;
}

.horizontal p,
p.horizontal {
  /* layout.css */
  margin: 0 1em;

  height: 100%;

  overflow-y: auto;
  overflow-inline: auto;

  hanging-punctuation: none; /* 左右に余白が無いため、句読点をぶら下げると水平スクロールが発生する。 */
}

/* TODO: blockquote > p 以外の構造でもうまく動くようにする? */
:--blockquote.horizontal {
  margin: 0 1em; /* ./reset.css */
  border-width: calc(2 / 13 * 1rem) 0;
  /* padding: calc(1rem - 2 / 13 * 1rem) 0; */
  padding: calc(0.5rem - 2 / 13 * 1rem) 0; /* TODO: CSS Inline Layout Module Level 3 の leading-trim が十分実装されたら元に戾す。 */
  box-sizing: border-box;
  height: 100%;
  overflow-x: revert;
  overflow-y: auto;
}

:--blockquote.horizontal > p {
  @extend %horizontal;

  margin: revert;
  height: revert;
}

/* cf. ../horizontal.css */

.horizontal ol,
ol.horizontal {
  list-style-type: revert;
}

.horizontal a:not(:has(:--image)):any-link.external::before,
a:not(:has(:--image)):any-link.external.horizontal::before {
  content: none;
}

.horizontal a:not(:has(:--image)):any-link.external::after,
a:not(:has(:--image)):any-link.external.horizontal::after {
  display: inline-block;
  display: inline flow-root;
  font: var(--fa-font-solid);
  content: '\f35d'; /* fa-up-right-from-square */
  font-size: 61.8034%; /* φ⁻¹ */
  color: var(--color-text-dimmed);
  vertical-align: super;
  margin-inline-start: 2px;
}

/* TODO: 主要ブラウザーと css-has-pseudo が :lang(ja, zh) をサポートしたら、このルールセットを削除する。 */
.horizontal ruby:lang(ja):has(rt:lang(ja)),
.horizontal ruby:lang(ja):has(rt:lang(jpn-archaic)),
.horizontal ruby:lang(ja):has(rt:lang(zh)),
.horizontal ruby:lang(jpn-archaic):has(rt:lang(ja)),
.horizontal ruby:lang(jpn-archaic):has(rt:lang(jpn-archaic)),
.horizontal ruby:lang(jpn-archaic):has(rt:lang(zh)),
.horizontal ruby:lang(zh):has(rt:lang(ja)),
.horizontal ruby:lang(zh):has(rt:lang(jpn-archaic)),
.horizontal ruby:lang(zh):has(rt:lang(zh)),
ruby:lang(ja):has(rt:lang(ja)).horizontal,
ruby:lang(ja):has(rt:lang(jpn-archaic)).horizontal,
ruby:lang(ja):has(rt:lang(zh)).horizontal,
ruby:lang(jpn-archaic):has(rt:lang(ja)).horizontal,
ruby:lang(jpn-archaic):has(rt:lang(jpn-archaic)).horizontal,
ruby:lang(jpn-archaic):has(rt:lang(zh)).horizontal,
ruby:lang(zh):has(rt:lang(ja)).horizontal,
ruby:lang(zh):has(rt:lang(jpn-archaic)).horizontal,
ruby:lang(zh):has(rt:lang(zh)).horizontal,
.horizontal ruby:lang(ja):has(> rt:lang(ja)),
.horizontal ruby:lang(ja):has(> rt:lang(jpn-archaic)),
.horizontal ruby:lang(ja):has(> rt:lang(zh)),
.horizontal ruby:lang(jpn-archaic):has(> rt:lang(ja)),
.horizontal ruby:lang(jpn-archaic):has(> rt:lang(jpn-archaic)),
.horizontal ruby:lang(jpn-archaic):has(> rt:lang(zh)),
.horizontal ruby:lang(zh):has(> rt:lang(ja)),
.horizontal ruby:lang(zh):has(> rt:lang(jpn-archaic)),
.horizontal ruby:lang(zh):has(> rt:lang(zh)),
ruby:lang(ja):has(> rt:lang(ja)).horizontal,
ruby:lang(ja):has(> rt:lang(jpn-archaic)).horizontal,
ruby:lang(ja):has(> rt:lang(zh)).horizontal,
ruby:lang(jpn-archaic):has(> rt:lang(ja)).horizontal,
ruby:lang(jpn-archaic):has(> rt:lang(jpn-archaic)).horizontal,
ruby:lang(jpn-archaic):has(> rt:lang(zh)).horizontal,
ruby:lang(zh):has(> rt:lang(ja)).horizontal,
ruby:lang(zh):has(> rt:lang(jpn-archaic)).horizontal,
ruby:lang(zh):has(> rt:lang(zh)).horizontal {
  ruby-align: revert;
  ruby-overhang: revert;
}

/* TODO: 主要ブラウザーと css-has-pseudo が :has(> E) をサポートしたら、上2つのセレクターを削除する。 */
.horizontal ruby:lang(ja, jpn-archaic, zh):has(rt:lang(ja, jpn-archaic, zh)),
ruby:lang(ja, jpn-archaic, zh):has(rt:lang(ja, jpn-archaic, zh)).horizontal,
.horizontal ruby:lang(ja, jpn-archaic, zh):has(> rt:lang(ja, jpn-archaic, zh)),
ruby:lang(ja, jpn-archaic, zh):has(> rt:lang(ja, jpn-archaic, zh)).horizontal {
  /* TODO: 熟字訓のグループルビは中央寄せする。 */
  /* TODO: 4文字以上のルビは下から順に上下に1文字づつ追い出す。 */
  /* TODO: 行頭、行末ではルビを版面の外に出さない。 */
  ruby-align: revert;
  ruby-overhang: revert;
}

.horizontal em,
em.horizontal {
  font-style: revert; /* italic */
}

/* .horizontal em,
em.horizontal, */
.horizontal em :where([lang]),
em.horizontal :where([lang]),
em .horizontal:where([lang]) {
  font-style: italic;
}

.horizontal em:where(:lang(ja)),
.horizontal em:where(:lang(jpn-archaic)),
em:where(:lang(ja)).horizontal,
em:where(:lang(jpn-archaic)).horizontal,
.horizontal em :where(:lang(ja)),
.horizontal em :where(:lang(jpn-archaic)),
em.horizontal :where(:lang(ja)),
em.horizontal :where(:lang(jpn-archaic)),
em :where(:lang(ja)).horizontal,
em :where(:lang(jpn-archaic)).horizontal {
  font-style: normal;
  font-family: var(--font-text-emphasized);
}

.horizontal em:where(:lang(ja, jpn-archaic)),
em:where(:lang(ja, jpn-archaic)).horizontal,
.horizontal em :where(:lang(ja, jpn-archaic)),
em.horizontal :where(:lang(ja, jpn-archaic)),
em :where(:lang(ja, jpn-archaic)).horizontal { font-style: normal;
  font-family: var(--font-text-emphasized);
}

.horizontal b,
b.horizontal {
  text-emphasis-style: revert;
  text-decoration: underline;
}

.horizontal .sideways,
.sideways.horizontal {
  font-feature-settings: revert;
  text-orientation: revert;
}

.horizontal .upright,
.upright.horizontal {
  font-feature-settings: revert;
  text-orientation: revert;
}

.horizontal .tate-chu-yoko,
.tate-chu-yoko.horizontal {
  -webkit-text-combine: revert;
  text-combine-upright: revert;
}

.horizontal table,
table.horizontal {
  margin-inline: revert;
}

.horizontal .header-anchor,
.header-anchor.horizontal {
  @extend .upright;
}

.horizontal .footnote-ref a[id],
.footnote-ref.horizontal a[id],
.footnote-ref a[id].horizontal {
  scroll-margin: 1em; /* スクロール方向が分からないので四方に同じように取る */
}
